<template>
  <div class="containers">
    <div class="title">订立固定期限劳动合同申请书声明</div>
    <div class="inputBox">
      <div class="inputs">
        <span style="flex-shrink: 0">申请人：</span>
        <van-field class="inputsItem" v-model="query.name" />
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">身份证号码：</span>
        {{query.ID_number}}
      </div>
      <div class="inputs">
        <span style="flex-shrink: 0">部门：</span>
        <van-field class="inputsItem" v-model="query.dept_name" />
      </div>
      <div class="itemTitle">山东新明辉安全科技有限公司:</div>
      <div class="contract-term selfStyle">
        <span class="textIndent">
          根据《劳动合同法》“有下列情形之一，劳动者提出或者同意续订、订立劳动合同的，除劳动者提出订立固定期限劳动合同外，应当订立无固定期限劳动合同:劳动者在该用人单位连续工作满十年的;用人单位初次实行劳动合同制度或者国有企业改制重新订立劳动合同时，劳动者在该用人单位连续工作满十年且距法定退休年龄不足十年的;连续订立二次固定期限劳动合同，且劳动者没有本法第三十九条和第四十条第一项、第二项规定的情形，续订劳动合同的”的规定，本人与公司的劳动合同即将于
        </span>
        <van-field class="inputs" v-model="query.ht_year" style="width: 70px;" />年，
        <van-field class="inputs" v-model="query.ht_month" style="width: 70px;" />月，
        <van-field class="inputs" v-model="query.ht_day" style="width: 70px;" />日，
      </div>
          本人自愿向贵公司提出申请，申请与贵公司签订合同期限为
        <van-field class="inputs input50" v-model="query.year" style="width: 60px;display: inline-block;text-align: center" />年
      <div class="itemTitle">
        <div class="sign">
          <span class="signLabel">申请人:</span>
          <vue-esign v-if="!query.sign_file" id="sign"  class="card" ref="esign" :width="600" :height="350" :isCrop="false" :lineWidth="10" :bgColor.sync="bgColor" :lineColor="'#000'" />
          <img v-else class="card" :src="query.sign_file" alt="">
          <div class="reset" @click="resetSign">重新签名</div>
        </div>
      </div>
      <div class="itemTitle contract-term selfStyle">
        申请时间：
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_year" style="width: 60px;" />
        </span>年
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_month" style="width: 60px;" />
        </span>月
        <span class="date-placeholder">
        <van-field class="inputs input50" v-model="query.select_day" style="width: 60px;" />
        </span>日
      </div>

      <div class="footerBar">
        <img v-if="query.seal_path" class="hetong" :src="query.seal_path" alt="">
        <div class="submitBtn" @click="submitInfo">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {SaveRegularContract, LoadRegularContract, uploadFile} from "@/api/paper";

export default {
  data() {
    return {
      query: {},
      bgColor: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    resetSign() {
      if(this.query.sign_file) {
        this.query.sign_file = ''
      } else {
        this.$refs.esign.reset()
      }
    },
    loadData() {
      LoadRegularContract({openId: this.$store.state.info.openId}).then(res => {
        if(res.row && res.row.name) {
          this.query = res.row
        }
      })
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async drawImg() {
      this.$refs.esign.generate().then(res => {
        var blob = this.dataURLtoBlob(res)
        var tofile = this.blobToFile(blob, '签名.png')
        setTimeout(async () => {
          const formData = new FormData()
          formData.append('file', tofile, tofile.name)
          // ajax 请求
          uploadFile(formData).then(res => {
            this.query.sign_file = res.filePath
            this.query.sign_pic_name = res.picName
            this.submit()
          })
        })
      })
    },
    submit() {
      this.query.openId = this.$store.state.info.openId
      SaveRegularContract(this.query).then(res => {
        if(res.result) {
          this.$notify({
            type: "success",
            message: "提交成功",
          });
          this.$router.go(-1)
        } else {
          this.$notify({
            type: "warning",
            message: res.msg,
          });
        }
      })
    },
    submitInfo() {
      if(!this.query.name) {
        return  this.$notify({
          type: "warning",
          message: "请输入乙方名称",
        });
      }
      if(!this.query.dept_name) {
        return  this.$notify({
          type: "warning",
          message: "请输入部门名称",
        });
      }
      if(!this.query.ht_year || !this.query.ht_month || !this.query.ht_day || !this.query.year) {
        return  this.$notify({
          type: "warning",
          message: "请将时间填写完整",
        });
      }
      if(!this.query.sign_file && !this.$refs.esign.hasDrew ) {
        return this.$notify({
          type: "warning",
          message: "请签名~",
        });
      }
      if(!this.query.select_year || !this.query.select_month || !this.query.select_day) {
        return  this.$notify({
          type: "warning",
          message: "请将时间填写完整",
        });
      }
      if(this.query.sign_file) {
        this.submit()
      } else {
        this.drawImg()
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.hetong {
  width: 180px;
  height: 180px;
}
.containers {
  box-sizing: border-box;
  padding: 28px;
  font-family: PingFang SC;
  font-size: 12px;
  color: #777777;
  line-height: 16px;
}
.inputs {
  display: flex;
  align-items: center;
  .inputsItem {
    font-size: 12px;
    border-bottom: 1px solid #DDDDDD;
    text-align: center !important;
    padding: 0 !important;
  }
}
.footerBar {
  text-align: right;
}
.submitBtn {
  background: #02DBCA;
  border-radius: 20px;
  color: #FFFFFF;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 60px 0;
  color: #000000;
}
.inputBox {
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  color: #666666;
  line-height: 20px;
  .itemTitle {
    font-weight: bold;
  }
}
.inputs {
  font-size: 12px;
  border-bottom: 1px solid #DDDDDD;
  text-align: center !important;
  padding: 0 !important;
}
.input50 {
  width: 45px !important;
}
.input130 {
  width: 130px;
}
.input80 {
  width: 80px;
}
.textIndent {
  text-indent: 2em;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.contract-term {
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-wrap: wrap; /* 允许内容自动换行 */
}
.sign {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
  flex-wrap: wrap;
  .signLabel {
    flex-shrink: 0;
    font-family: PingFang SC;
    font-size: 15px;
    color: #666666;
  }
  .card {
    width: 100%;
    height: 70px;
    background: #F7F8FA;
    border: 1px solid #DDDDDD;
    margin-left: 22px;
  }
}
.reset {
  width: 200rpx;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #02DBCA;
  color: #02DBCA;
  border-radius: 10px;
  margin-top: 15px;
}
</style>
<style>
.selfStyle .van-field__control {
  text-align: center !important;
}
</style>
